import api from 'configs/api'

export interface AddressProps {
  city: string
  complement: string
  neighborhood: string
  number: string
  state: string
  street: string
  zip: string
  country?: any | null
}
export interface SchedulingProps {
  uuid: string
  nameDoctor: string
  schedulingDate: string
  specialty: string
  description: string
  status: number
  statusDescription: string
  imgDoctor: string
  cancelService: string
  prescription: any
}
export interface UserProps {
  uuid: string
  birthDay: string
  cellphone: string
  ddi: string
  document: string
  documentType: string
  email: string
  name: string
  type: string
  gender: string
  status?: string
}
export interface SingleQueriesProps {
  id: number
  medicalSpecialty: string
  productUuid: string
  quantity: number
  status: number
  statusDescription: string | null
  unitPrice: string
  uuid: string
  isReturn: boolean
}
export interface PlanProps {
  title: string
  description?: string
  countScheduling: number
  countSchedulingMonth: number
  dueDate: string
  consultationsPerformed: number
  status: number
  statusDescription?: string
  canceledDate: string | null
  canceledDescription: string
  reasonCanceled: string
  aggregate?: any[]
  company: any | null
}

export interface UserData {
  user: UserProps
  address: AddressProps
  sigleQueries: SingleQueriesProps[]
  plan: PlanProps
  aggregates: any
}
export interface userUpdatePrps {
  uuid: string
  userData: {
    name: string
    email: string
    cellphone: string
    document: string
    birthDay: string
    gender: string | null
    ddi: string
  }
  address: AddressProps
}
export const getDataUser = async (uuid: string) => {
  try {
    const response = await api.get(`/manager/client-infos/${uuid}`)
    const { item } = response.data

    const address: AddressProps = {
      city: item?.user?.address?.city || '',
      complement: item?.user?.address?.complement || '',
      neighborhood: item?.user?.address?.neighborhood || '',
      number: item?.user?.address?.number || '',
      state: item?.user?.address?.state || '',
      street: item?.user?.address?.street || '',
      zip: item?.user?.address?.zipcode || '',
      country: item?.user?.address?.country || '',
    }
    const user: UserProps = {
      uuid: item?.user?.id || uuid,
      birthDay: item?.user?.profiles?.birthdate || '',
      cellphone: item?.user?.profiles?.cellphone || '',
      ddi: item?.user?.profiles?.ddi || '',
      document: item?.user?.document || '',
      documentType: item?.user?.document_type || '',
      email: item?.user?.email || '',
      name: item?.user?.name || '',
      type: item?.user?.type || '',
      gender: item?.user?.profiles?.gender || '',
      status: item?.user.status || '',
    }

    return {
      status: true,
      userDatas: {
        address,
        user,
        sigleQueries: item?.separate_consultation,
        plan: item?.plan,
        aggregates: item?.aggregates,
      },
    }
  } catch (err: any) {
    return {
      status: false,
      message: err,
    }
  }
}

export const updateClient = async ({
  uuid,
  userData,
  address,
}: userUpdatePrps) => {
  try {
    const response = await api.put(`/accounts/ms/v1/user/${uuid}/all-info`, {
      user: {
        name: userData.name,
        email: userData.email,
        cellphone: userData.cellphone,
        document: userData.document,
        birthdate: userData.birthDay,
        gender: userData.gender,
        ddi: userData.ddi,
      },
      address: {
        street: address.street,
        number: address.number,
        complement: address.complement,
        zipcode: address.zip,
        neighborhood: address.neighborhood,
        city: address.city,
        state: address.state,
        country: address.country || 'BR',
      },
    })
    return {
      status: true,
      message: response?.data?.message || 'Usuário atualizado com sucesso.',
    }
  } catch (err: any) {
    return {
      status: false,
      message: err?.response?.data?.message,
    }
  }
}

export const createConsultation = async (uuid: string, specialty: string) => {
  try {
    await api.post(`manager/client/separate-consultation`, {
      user_id: uuid,
      specialty,
    })
  } catch (err) {
    return {
      status: false,
      message: err,
    }
  }
  return {
    status: true,
    message: 'Consulta avulsa concedida com sucesso.',
  }
}

export const getSeparateConsultation = async () => {
  try {
    const response = await api.get(
      `/payments/v1/products?for_subscriber=false`,
      {
        headers: {
          'Accept-language': 'pt-BR',
        },
      }
    )
    return {
      status: true,
      message: 'Consultas avulsas recuperada com sucesso.',
      data: response.data,
    }
  } catch (err) {
    return {
      status: false,
      message: err,
    }
  }
}
