import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { FormikProvider, useFormik, Field } from 'formik'
import * as yup from 'yup'
import {
  cpfCnpjMask,
  cpfCnpjUnmask,
  cepMask,
  cepUnmask,
  telephoneMask,
  telephoneUnmask,
} from 'js-essentials-functions'
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'

// Components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  Media,
} from 'reactstrap'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import ComponentBack from 'components/ComponentBack'
import Loading from 'components/Loading'
import api from 'configs/api'
import { alertSuccess, alertWarning } from 'utils/toast'
import useSpecialty from 'hooks/useSpecialty'

import { translateSpecialtyName } from 'utils/translateSpecialty'

const schemaRegister = yup.object().shape({
  legalName: yup.string().required('Informe o nome Jurídico!'),
  fantasyName: yup.string().required('Informe o nome Fantasia!'),
  cnpj: yup.string().required('Informe o cnpj!'),
  email: yup.string().required('Informe o email!'),
  cellphone: yup.string().required('Informe o telefone!'),
  street: yup.string().required('Informe a rua!'),
  number: yup.string().required('Informe o número!'),
  plan: yup.string().required('Informe o Plano!'),
  neighborhood: yup.string().required('Informe o bairro!'),
  city: yup.string().required('Informe a cidade!'),
  state: yup.string().required('Informe o estado!'),
  zip: yup.string().required('Informe o cep!'),
})

const NewCompany = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const [imageLogo, setImageLogo] = useState<any>()
  const [plans, setPlans] = useState<Array<any>>([])
  const { filterSpecialtiesActive, getSpecialties } = useSpecialty()
  const initialValues = useMemo(
    () => ({
      legalName: '',
      fantasyName: '',
      cnpj: '',
      specialties: [],
      email: '',
      cellphone: '',
      street: '',
      number: '',
      plan: '',
      neighborhood: '',
      city: '',
      state: '',
      zip: '',
    }),
    []
  )
  const getPlans = async () => {
    try {
      const res = await api.get(`/payments/ms/v1/plans/star-company`)
      setPlans(res.data)
    } catch (err) {
      console.log(err)
    }
  }

  function removeAccentsAndLowercase(str: any) {
    const normalizedStr = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    const lowerFirstLetter =
      normalizedStr.charAt(0).toLowerCase() + normalizedStr.slice(1)

    return lowerFirstLetter
  }
  const onDrop = useCallback((acceptedFiles) => {
    setImageLogo(acceptedFiles)
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      setLoading(true)

      const checkedSpecialty = () =>
        values.specialties
          .map((value: string) => {
            const specialty = filterSpecialtiesActive().find(
              (spec: any) => spec.name === value
            )
            return specialty
              ? {
                  specialty_id: specialty.id,
                  slug: removeAccentsAndLowercase(specialty.name),
                  langs: [
                    { lang: 'pt-BR', name: specialty.name },
                    {
                      lang: 'en-US',
                      name: translateSpecialtyName(specialty.name),
                    },
                  ],
                }
              : null
          })
          .filter((specialty: any) => specialty !== null)

      const data: any = {
        national_registration: cpfCnpjUnmask(values.cnpj),
        legal_name: values.legalName,
        fantasy_name: values.fantasyName,
        email: values.email.trim(),
        telephone: telephoneUnmask(values.cellphone),
        cellphone: telephoneUnmask(values.cellphone),
        street: values.street,
        number: values.number,
        neighborhood: values.neighborhood,
        city: values.city,
        state: values.state,
        country: 'BR',
        zipcode: cepUnmask(values.zip),
        plan_id: values?.plan,
        specialties: checkedSpecialty(),
      }

      if (values.specialties.length === 0) {
        alertWarning('É necessário selecionar pelo menos uma especialidade.')
      } else {
        try {
          const companyResponse = await api.post(
            '/company/ms/v1/companies',
            data
          )

          if (imageLogo?.length) {
            const image = new FormData()
            imageLogo.forEach((file: any) => image.append('file', file))
            image.append(
              'folder',
              `companies/${companyResponse?.data?.id}/logo`
            )

            const imageLogResponse = await api.post('/files/file/upload', image)
            const imageUrl = imageLogResponse?.data?.files?.[0]?.publicURL

            await api.put(`/company/ms/v1/logo/${companyResponse?.data?.id}`, {
              url: imageUrl,
            })
          }

          alertSuccess('Empresa cadastrada com sucesso!')
          history.goBack()
        } catch (err: any) {
          console.log(err.response)
          alertWarning(
            err.response?.data?.message ||
              'Não foi possível cadastrar a empresa.'
          )
        } finally {
          setLoading(false)
        }
      }
    },
  })

  const getAddress = async (cep: string) => {
    setLoading(true)
    fetch(`https://api.pagar.me/1/zipcodes/${cep}`, {
      method: 'get',
    })
      // eslint-disable-next-line consistent-return
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
      })
      .then((res) => {
        setLoading(false)
        formik.setValues((v: any) => ({
          ...v,
          zip: cep,
          street: res.street || '',
          neighborhood: res.neighborhood || '',
          city: res.city || '',
          state: res.state || '',
        }))
      })
      .catch(() => {
        setLoading(false)
        formik.setValues((v: any) => ({
          ...v,
          zip: cep,
        }))
      })
  }
  const handleUpdateAddress = (cep: string) => {
    if ((cepUnmask(cep) || cep === '') && cepUnmask(cep).length < 8) {
      formik.setValues((v: any) => ({ ...v, zip: cep }))
    } else {
      getAddress(cepUnmask(cep))
    }
  }

  useEffect(() => {
    getPlans()
    getSpecialties()
  }, [])

  if (loading) return <Loading title="Aguarde.." />
  return (
    <>
      <HeaderNeutro />
      <ComponentBack />
      <Container className="mt-3" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-white shadow">
              <CardBody>
                <h6 className="heading-small text-muted mb-4">
                  Informe os dados abaixo para cadastrar uma nova empresa
                </h6>
                <FormikProvider value={formik}>
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Nome Jurídico
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Informe o Nome Jurídico.."
                              name="legalName"
                              type="text"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.legalName}
                              error={formik.errors.legalName}
                            />
                            {formik.errors.legalName &&
                              formik.touched.legalName && (
                                <div className="input-feedback">
                                  {formik.errors.legalName}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Nome Fantasia
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Informe o nome.."
                              name="fantasyName"
                              type="text"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.fantasyName}
                              error={formik.errors.fantasyName}
                            />
                            {formik.errors.fantasyName &&
                              formik.touched.fantasyName && (
                                <div className="input-feedback">
                                  {formik.errors.fantasyName}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              CNPJ
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Informe o CNPJ.."
                              name="cnpj"
                              type="text"
                              maxLength={18}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={cpfCnpjMask(formik.values.cnpj || '')}
                              error={formik.errors.cnpj}
                            />
                            {formik.errors.cnpj && formik.touched.cnpj && (
                              <div className="input-feedback">
                                {formik.errors.cnpj}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col
                          sm="3"
                          className="d-flex flex-column align-items-center "
                        >
                          <Media
                            style={{
                              cursor: 'pointer',
                            }}
                            className="d-flex flex-column align-items-center "
                            {...getRootProps()}
                          >
                            <label
                              className="form-control-label"
                              htmlFor="logo"
                            >
                              Logo
                            </label>
                            <input {...getInputProps()} />
                            <Media
                              style={{
                                width: '150px',
                                height: '100px',
                                borderRadius: '8px',
                                border: '3px dashed #dee2e6',
                              }}
                              className=" align-items-center justify-content-center position-relative"
                            >
                              {imageLogo ? (
                                <img
                                  src={URL.createObjectURL(imageLogo[0])}
                                  alt="Logo"
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                  }}
                                />
                              ) : (
                                <h5 className=" text-gray">Adicionar</h5>
                              )}
                            </Media>
                          </Media>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="email"
                            >
                              Email
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Informe o Email.."
                              name="email"
                              type="text"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.email || ''}
                              error={!!formik.errors.email}
                            />
                            {formik.errors.email && formik.touched.email && (
                              <div className="input-feedback">
                                {formik.errors.email}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="cellphone"
                            >
                              Telefone
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Informe o telefone.."
                              name="cellphone"
                              type="text"
                              maxLength={15}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={telephoneMask(
                                formik.values.cellphone || ''
                              )}
                              error={formik.errors.cellphone}
                            />
                            {formik.errors.cellphone &&
                              formik.touched.cellphone && (
                                <div className="input-feedback">
                                  {formik.errors.cellphone}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="plan"
                            >
                              Selecione o plano
                            </label>
                            <Input
                              type="select"
                              id="plan"
                              name="plan"
                              value={formik.values.plan}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option value="" selected>
                                Selecione...
                              </option>
                              {plans.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.title}
                                </option>
                              ))}
                            </Input>
                            {formik.errors.plan && formik.touched.plan && (
                              <div className="input-feedback">
                                {formik.errors.plan}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <hr style={{ marginTop: 0 }} />
                      <Row className="d-flex">
                        <Col md="3">
                          <FormGroup>
                            <Label for="zip" style={{ fontSize: 14 }}>
                              CEP
                            </Label>
                            <Input
                              className="form-control-alternative"
                              name="zip"
                              type="text"
                              maxLength={9}
                              onBlur={formik.handleBlur}
                              onChange={(e) =>
                                handleUpdateAddress(e.target.value)
                              }
                              value={cepMask(formik.values.zip)}
                              error={formik.errors.zip}
                            />
                            {formik.errors.zip && formik.touched.zip && (
                              <div className="input-feedback">
                                {formik.errors.zip}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label for="street" style={{ fontSize: 14 }}>
                              Rua
                            </Label>
                            <Input
                              className="form-control-alternative"
                              name="street"
                              type="text"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.street}
                              error={formik.errors.street}
                            />
                            {formik.errors.street && formik.touched.street && (
                              <div className="input-feedback">
                                {formik.errors.street}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label for="number" style={{ fontSize: 14 }}>
                              Nº
                            </Label>
                            <Input
                              className="form-control-alternative"
                              name="number"
                              type="text"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.number}
                              error={formik.errors.number}
                            />
                            {formik.errors.number && formik.touched.number && (
                              <div className="input-feedback">
                                {formik.errors.number}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <Label for="state" style={{ fontSize: 14 }}>
                              Estado
                            </Label>
                            <Input
                              className="form-control-alternative"
                              name="state"
                              type="text"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.state}
                              error={formik.errors.state}
                            />
                            {formik.errors.state && formik.touched.state && (
                              <div className="input-feedback">
                                {formik.errors.state}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label for="neighborhood" style={{ fontSize: 14 }}>
                              Bairro
                            </Label>
                            <Input
                              className="form-control-alternative"
                              name="neighborhood"
                              type="text"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.neighborhood}
                              error={formik.errors.neighborhood}
                            />
                            {formik.errors.neighborhood &&
                              formik.touched.neighborhood && (
                                <div className="input-feedback">
                                  {formik.errors.neighborhood}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label for="city" style={{ fontSize: 14 }}>
                              Cidade
                            </Label>
                            <Input
                              className="form-control-alternative"
                              name="city"
                              type="text"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.city}
                              error={formik.errors.city}
                            />
                            {formik.errors.city && formik.touched.city && (
                              <div className="input-feedback">
                                {formik.errors.city}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between align-items-end">
                        <Col lg="auto">
                          <h3 className="form-control-label">
                            Especialidades acessíveis na empresa
                          </h3>
                          <div className="overflow-auto d-flex ">
                            <FormGroup className="d-flex flex-wrap">
                              {filterSpecialtiesActive().map((specialty) => (
                                <Label
                                  className="mr-3 align-items-center justify-content-center"
                                  size="sm"
                                  key={specialty.id}
                                >
                                  <Field
                                    name="specialties"
                                    type="checkbox"
                                    className="mr-2 align-items-center justify-content-center"
                                    value={specialty.name}
                                  />
                                  {specialty.name}
                                </Label>
                              ))}
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-end ">
                        <Col lg="auto">
                          <Button
                            className="mt-3"
                            color="warning"
                            type="submit"
                            disabled={!formik.isValid}
                          >
                            Cadastrar
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </FormikProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default NewCompany
