import React, { useState, useEffect } from 'react'

import {
  CardHeader,
  Container,
  CardBody,
  Row,
  Card,
  Col,
  FormGroup,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Table,
} from 'reactstrap'
import ReactDatetime from 'react-datetime'

import HeaderNeutro from 'components/Headers/HeaderNeutro'
import Loading from 'components/Loading'

import api from 'configs/api'
import { alertDanger } from 'utils/toast'

import moment from 'moment'

const FoodMenu = () => {
  const [loading, setLoading] = useState(false)
  const [menu, setMenu] = useState<any>([])

  const [dates, setDates] = useState<any>({
    start: moment().format('YYYY-MM'),
  })

  const getMenu = async () => {
    setLoading(true)
    try {
      const res = await api.get(`/stela-meal/v1/reports?month=${dates?.start}`)

      setMenu(res.data)
    } catch (err: any) {
      console.log(err?.data)
      alertDanger('Erro ao carregar os dados do menu alimentar.')
    }
    setLoading(false)
  }

  useEffect(() => {
    getMenu()
  }, [dates])

  return (
    <div>
      {loading && <Loading title="Carregando..." />}
      <HeaderNeutro />
      <Container fluid className="nav-wrapper ">
        <CardBody className="p-0">
          <Container>
            <Row className="d-flex">
              <Col className="mb-4 d-flex align-items-stretch justify-between">
                <Card className="shadow h-100 w-100">
                  <Row className="align-items-center mt-4 ml-2 mr-2">
                    <Col xs={6} md={4}>
                      <FormGroup>
                        <h5 className="mb-2 text-black">Selecione um mês</h5>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: 'Selecione a data',
                            }}
                            value={dates.start}
                            dateFormat="YYYY-MM"
                            timeFormat={false}
                            closeOnSelect
                            onChange={(e: any) => {
                              setDates({
                                ...dates,
                                start: moment(e).format('YYYY-MM'),
                              })
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>

                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col sm="12">
                        <h3 className="mb-0">Dados</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Table
                    className="align-items-center table-flush d-flex"
                    responsive
                  >
                    <Col>
                      <table style={{ width: '100%' }}>
                        <tr>
                          <th>Total</th>
                          <td>{menu?.total || 0}</td>
                        </tr>

                        <tr>
                          <th>Usuários únicos</th>
                          <td>{menu?.uniqueUsersCount || 0}</td>
                        </tr>
                      </table>
                    </Col>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Container>
    </div>
  )
}

export default FoodMenu
